import { Playlist, PlaylistPrivacy } from "@prisma/client"
import { toQueryString } from "./utils.embed"
import { GetUserUsernameOptions } from "./utils.user"

type PermalinkArgs = Pick<Playlist, "privacy" | "id" | "uuid">
export function getPlaylistPermalinkUrl(playlist: PermalinkArgs) {
	return `${process.env.BASE_URL}/playlists/${playlist.uuid}`
}

export function getPlaylistPath(playlist: PermalinkArgs) {
	if (playlist.privacy == "PUBLIC") return `/playlists/${playlist.id}`
	if (playlist.privacy == "UNLISTED") return `/playlists/${playlist.uuid}`
	return null
}

export function hasEditPermission(user: any, playlist: Partial<Playlist>) {
	if (!user || !playlist) return false
	return user?.role == "ADMIN" || isOwner(user, playlist)
}

export function isOwner(user: any, playlist: any) {
	let userId = null
	if ("user" in playlist) {
		userId = playlist.user?.id
	} else if ("userId" in playlist) {
		userId = playlist.userId
	}

	if (userId === undefined) {
		throw new Error("isOwner: playlist.userId and playlist.user.id are undefined")
	}
	return user && user.id == userId
}

export function getPermalinkPath(
	user: GetUserUsernameOptions,
	playlist: { privacy: PlaylistPrivacy; uuid: string; id: number },
	query?: any,
) {
	let querystring = toQueryString(query)
	if (querystring) querystring = "?" + querystring

	if (playlist.privacy == "UNLISTED") {
		return `/playlists/${playlist.uuid}${querystring}`
	} else {
		return `/playlists/${playlist.id}${querystring}`
	}
}

export const holographicPlaylistTitles: string[] = [
	"Holo-History Highlights",
	"Retro-Futuristic Rendezvous",
	"DeLorean Dreams",
	"Flux Capacitor Flashes",
	"Vintage Visions",
	"Time Traveler's Trove",
	"Doc's Discovery",
	"Einstein's Echoes",
	"Plutonium Panorama",
	"80s Odyssey",
	"Frayne's Favorites",
	"Hoverboard Horizons",
	"Temporal Tapestries",
	"Gigawatt Glimpses",
	"Clock Tower Chronicles",
	"Hovercar Haven",
	"Fusion Fables",
	"Skateboard Skies",
	"Laserdisc Layers",
	"Cafe 80s Collage",
	"Science & Silhouettes",
	"Hydrator Highlights",
	"Rock 'n Roll Rarities",
	"Space-Time Spectrum",
	"Texaco Towers",
	"Atomic Age Assemblage",
	"Vortex Visions",
	"Timepiece Treasures",
	"Hover Train Trails",
	"Retro Reverb",
	"Futuristic Fantasies",
	"Silicon Dreams",
	"Holographic Harmony",
	"Neon Nostalgia Nights",
	"Lo-Fi Lunar Landings",
	"Vaporwave Voyages",
	"Chillwave Chronicles",
	"Synth Sunset Serenades",
	"Pixelated Paradise",
	"Retrograde Reverie",
	"Cosmic Coffeehouse",
	"Astral Ambient Arrays",
	"Digital Daydreams",
	"Phaser Phantasms",
	"Melodic Metropolis",
	"Cybernetic Serenity",
	"Starry Sky Soiree",
	"Glitch Grove",
	"Pastel Pixel Party",
	"Neon Nature",
	"Ethereal Elevator Echoes",
	"Lo-Fi Luminance",
	"Vapor Visions",
	"Synthwave Symphony",
	"Chromatic Chill",
	"Retro Reflections",
	"Dreamwave Doodles",
	"Soft Synth Sunrise",
]
