import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid"
import { Tooltip } from "./Tooltip"
import { classNames } from "lib/classNames"
import { trpc } from "lib/trpc/trpc"
import { useBlocksUserStore } from "@/hooks/useStore"
import { toast } from "react-hot-toast"

export interface BanHologramButtonProps {
	id: number
	className?: string
}
export default function BanHologramButton({ id, className }: BanHologramButtonProps) {
	const { mutateAsync } = trpc.hologram.setShadowBanStatus.useMutation()
	const isModerator = useBlocksUserStore((state) => state.current?.isModerator)
	const { data: isShadowBanned, refetch } = trpc.hologram.getShadowBanStatus.useQuery(
		{ id },
		{
			enabled: !!isModerator,
		},
	)

	const toggleBan = async (e) => {
		e.preventDefault()
		await mutateAsync({ id, isShadowBan: !isShadowBanned })
		toast.success(!isShadowBanned ? "Hologram is banned!" : "Hologram isn't banned")
		refetch()
	}

	if (!isModerator) {
		return <></>
	}

	return (
		<Tooltip content={isShadowBanned ? "Unban" : "Shadow-Ban"}>
			<button
				onClick={toggleBan}
				id={`ban-hologram-btn`}
				className={classNames(
					"rounded-full border border-solid border-white/0 bg-white bg-opacity-20 p-3 transition-all hover:border-white/20 hover:shadow-lg",
					className?.match(/bg-/) ? "" : "bg-white ",
					className,
				)}>
				{isShadowBanned ? (
					<EyeIcon className="h-5 w-5 fill-white drop-shadow-lg" />
				) : (
					<EyeSlashIcon className="h-5 w-5 fill-white drop-shadow-lg" />
				)}
			</button>
		</Tooltip>
	)
}
