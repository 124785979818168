import React, { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={24}
		height={24}
		fill={props.fill}
		{...props}>
		<defs>
			<linearGradient id="myGradient">
				<stop offset="0%" stopColor="#5F15E8" />
				<stop offset="100%" stopColor="#A055FA" />
			</linearGradient>
		</defs>
		<path
			fill="url(#myGradient)"
			fillRule="evenodd"
			d="M18.707 7.293a1 1 0 0 1 0 1.414l-8.5 8.5a1 1 0 0 1-1.414 0l-3.5-3.5a1 1 0 1 1 1.414-1.414L9.5 15.086l7.793-7.793a1 1 0 0 1 1.414 0Z"
			clipRule="evenodd"
		/>
	</svg>
)
export default SvgComponent
