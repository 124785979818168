import { TRPCError } from "@trpc/server"
import CheckBox from "components/playlists/library/CheckBox"
import PlaylistThumbnail from "components/PlaylistThumbnail"
import { PrivacyIconWithToolTip } from "components/PrivacyIcon"
import { toastErrorStyle, toastSuccessStyle } from "components/toastStyles"
import { trpc } from "lib/trpc/trpc"
import { useCallback } from "react"
import { isMobile } from "react-device-detect"
import toast from "react-hot-toast"

interface addToPlaylistTitleArgs {
	id: number
	title: string
	privacy: "PUBLIC" | "UNLISTED" | "ONLY_ME" | "PASSWORD"
	onSelect: (id: number) => void
	hologram?: { id: number; title: string }
	onClose?: () => void
	mode: "SINGLE" | "SELECT"
	selected?: boolean
}

export default function AddToPlaylistTitle(args: addToPlaylistTitleArgs) {
	const { selected, id, hologram, onClose, title, mode, onSelect, privacy } = args
	const { data } = trpc.playlist.getPlaylistThumbnail.useQuery(
		{
			id,
		},
		{ enabled: isMobile && mode === "SELECT" },
	)

	const utils = trpc.useUtils()
	const { mutateAsync: addHologramsToPlaylists } = trpc.playlistItem.addHologramsToPlaylists.useMutation()

	const handleClick = useCallback(async (e) => {
		e.preventDefault()
		e.stopPropagation()
		try {
			if (!hologram) return
			await addHologramsToPlaylists({ hologramIds: [hologram.id], playlistIds: [id] })
			await utils.playlist.myPlaylists.invalidate()
			toast(`Added ${hologram.title} to ${title}!`, toastSuccessStyle)
		} catch (e) {
			const error = e as unknown as TRPCError
			toast(error.message, toastErrorStyle)
		}
		onClose?.()
	}, [])

	return (
		<div
			key={id}
			className="flex flex-row items-center justify-between gap-4 py-2 pr-2 md:w-full md:py-0 md:pr-0">
			<div
				className="pointer-events-none flex flex-row items-center justify-start gap-4 rounded-lg hover:bg-dark-purple-100/10 md:pointer-events-auto md:w-full md:cursor-pointer md:p-2 dark:md:hover:bg-white/10"
				onClick={handleClick}>
				{data && data.playlistItems && (
					<>
						{mode === "SELECT" && (
							<>
								<PlaylistThumbnail
									className="box h-12 w-12 min-w-[3rem] md:h-14 md:w-14"
									playlist={data.playlistItems}
									size={isMobile ? 48 : 56}
								/>
								<div className="box">
									<PrivacyIconWithToolTip privacy={privacy} />
								</div>
							</>
						)}
					</>
				)}

				{title}
			</div>
			{mode === "SELECT" && (
				<div className="box">
					<CheckBox
						selected={selected}
						onClick={(e) => {
							e.stopPropagation()
							e.preventDefault()
							onSelect(id)
						}}
					/>
				</div>
			)}
		</div>
	)
}
