import Popup from "components/Popup"
import AddHologramToPlaylistSubMenu from "components/playlists/addHologramToPlaylistSubMenu"

interface AddHologramToPlaylistMobilePopupArgs {
	hologram: { id: number; title: string }
	open: boolean
	setOpen: (open: boolean) => void
}

export default function AddHologramToPlaylistMobilePopup(args: AddHologramToPlaylistMobilePopupArgs) {
	return (
		<Popup
			className="max-w-xl "
			initialFocus={false}
			open={args.open}
			onClose={() => {
				args.setOpen(false)
			}}
			title={`add ${args.hologram.title} to a playlist`}>
			<AddHologramToPlaylistSubMenu
				hologram={args.hologram}
				onClose={() => args.setOpen(false)}
				mode={"SELECT"}
			/>
		</Popup>
	)
}
