import { useBlocksUserStore } from "@/hooks/useStore"
import { EmbedHologram } from "@/lib/hologramProps"
import {
	ArrowDownOnSquareStackIcon,
	CodeBracketIcon,
	NoSymbolIcon,
	PencilIcon,
	PlayCircleIcon,
	ShareIcon,
	TrashIcon,
} from "@heroicons/react/24/outline"
import {
	CheckIcon,
	EllipsisHorizontalIcon,
	EllipsisVerticalIcon,
	EyeSlashIcon,
	FlagIcon,
	GlobeAmericasIcon,
	LinkIcon,
	LockClosedIcon,
} from "@heroicons/react/24/solid"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { PlaylistPrivacy } from "components/playlists/PrivacyDropDown"
import { Tooltip } from "components/Tooltip"
import useCopyToClipboard from "hooks/useCopyToClipboard"
import useUpdateHolograms from "hooks/useUpdateHolograms"
import { classNames } from "lib/classNames"
import { getEmbedCode } from "lib/utils.embed"
import {
	canAddToPlaylist,
	getHologramSettingsPath,
	getPermalinkUrl,
	hasEditPermission,
	isOwner,
} from "lib/utils.hologram"
import { useRouter } from "next/router"
import { useState } from "react"
import { toast } from "react-hot-toast"
import Badge from "./Badge"
import { FlagHologram } from "./moderation/FlagHologramPopup"
import AddToPlaylistSubMenu from "./playlists/addHologramToPlaylistSubMenu"
import { toastErrorStyle, toastSuccessStyle } from "./toastStyles"

type HologramPrivacy = "PUBLIC" | "UNLISTED" | "ONLY_ME"

interface HologramDropDownArgs {
	className?: string
	hologram: EmbedHologram
	privacy: HologramPrivacy
	forceDarkMode?: boolean
	ellipsis?: "HORIZONTAL" | "VERTICAL"
	setPrivacy: (privacy: HologramPrivacy) => void
	setDownloadPopup(hologram: EmbedHologram): void
	setSharePopup(hologram: EmbedHologram): void
	setFlagPopup(hologram: FlagHologram): void
	setDeletePopup(id: number[]): void
}

export default function HologramDropDown(args: HologramDropDownArgs) {
	const { forceDarkMode } = args
	const flags = useBlocksUserStore((state) => state.current?.flags)
	const ellipsis = args.ellipsis ?? "HORIZONTAL"
	const downloadAllFlag = flags?.includes("HOLOGRAMS_DOWNLOAD_ALL") ?? false

	const { updateHologramPrivacy, updateHologramDownloadable } = useUpdateHolograms()
	const [canUsersDownload, setCanUsersDownload] = useState<boolean>(
		args.hologram.canUsersDownload || downloadAllFlag,
	)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const handlePrivacyChange = async (newPrivacy: HologramPrivacy) => {
		const currentPrivacy = args.privacy
		args.setPrivacy(newPrivacy) // Optimistic update
		try {
			const updatedPrivacy = await updateHologramPrivacy(args.hologram.id, newPrivacy) // Update on server
			toast(
				`Updated Hologram to ${
					updatedPrivacy === "ONLY_ME"
						? "Private"
						: updatedPrivacy === "UNLISTED"
							? "Unlisted"
							: updatedPrivacy === "PUBLIC"
								? "Public"
								: ""
				}`,
				{
					...toastSuccessStyle,
					icon:
						updatedPrivacy === "ONLY_ME" ? (
							<LockClosedIcon className="w-6" />
						) : updatedPrivacy === "UNLISTED" ? (
							<LinkIcon className="w-6" />
						) : updatedPrivacy === "PUBLIC" ? (
							<GlobeAmericasIcon className="w-6" />
						) : (
							""
						),
				},
			)
			// Additional success handling if needed
		} catch (error) {
			// Revert to previous state on error
			args.setPrivacy(currentPrivacy)
			console.error("Failed to update privacy", error)
			toast("Oh no! an error occured 😱", toastErrorStyle)
		}
	}

	const toggleCanUsersDownload = async () => {
		try {
			setCanUsersDownload(!canUsersDownload)
			const updateCanUsersDownload = await updateHologramDownloadable(args.hologram.id, !canUsersDownload)
			toast(
				updateCanUsersDownload
					? `Users can now download this hologram`
					: `Users can not download this hologram`,
				{
					...toastSuccessStyle,
					icon: updateCanUsersDownload ? (
						<CheckIcon className="w-6 stroke-green-500" />
					) : (
						<NoSymbolIcon className="w-6 stroke-red-500" />
					),
				},
			)
		} catch (error) {
			console.error("Failed to update downloadable", error)
			toast("Oh no! an error occured 😱", toastErrorStyle)
		}
	}

	return (
		<>
			<DropdownMenu.Root modal={true}>
				<style jsx global>
					{`
						.DropdownMenuContent {
							animation-duration: 0.6s;
							animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
						}
						.DropdownMenuContent[data-side="bottom"] {
							animation-name: slideDown;
						}

						[role^="menuitem"] {
							cursor: pointer;
						}

						div[data-radix-popper-content-wrapper] {
							z-index: 11 !important;
						}

						@keyframes slideDown {
							from {
								opacity: 0;
								transform: translateY(-10px);
							}
							to {
								opacity: 1;
								transform: translateY(0);
							}
						}
					`}
				</style>
				<Tooltip content="More Options">
					<DropdownMenu.Trigger
						onClick={(e) => {
							e.stopPropagation()
							e.preventDefault()
						}}
						className={classNames(
							args.className,
							"pointer-events-auto flex h-10 w-10 flex-row items-center justify-center gap-2 rounded-full px-1 transition-all active:bg-foil",
							isMenuOpen ? "md:opacity-100" : null,
						)}>
						{ellipsis === "HORIZONTAL" && (
							<EllipsisHorizontalIcon
								className={`pointer-events-none h-8 w-8 fill-dark-purple-100 ${
									forceDarkMode ? "fill-white" : "fill-dark-purple-100 dark:fill-white"
								} drop-shadow-lg dark:fill-white`}
							/>
						)}
						{ellipsis === "VERTICAL" && (
							<EllipsisVerticalIcon
								className={`pointer-events-none h-8 w-8 fill-dark-purple-100 ${
									forceDarkMode ? "fill-white" : "fill-dark-purple-100 dark:fill-white"
								} drop-shadow-lg dark:fill-white`}
							/>
						)}
					</DropdownMenu.Trigger>
				</Tooltip>
				<DropdownMenu.Portal>
					<DropDownContent
						privacy={args.privacy}
						handlePrivacyUpdate={handlePrivacyChange}
						canUsersDownload={canUsersDownload}
						toggleCanUsersDownload={toggleCanUsersDownload}
						hologram={args.hologram}
						setDownloadPopup={args.setDownloadPopup}
						setFlagPopup={args.setFlagPopup}
						setSharePopup={args.setSharePopup}
						setDeletePopup={args.setDeletePopup}
						onClose={() => {
							setIsMenuOpen(false)
						}}
					/>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
		</>
	)
}

function DropDownContent({ ...args }) {
	const router = useRouter()

	const navigate = () => {
		router.push(getHologramSettingsPath(args.hologram, router.asPath))
	}

	const [copiedText, setCopy] = useCopyToClipboard()

	const user = useBlocksUserStore((state) => state.dbUser)
	const flags = useBlocksUserStore((state) => state.current?.flags)
	const isAdmin = useBlocksUserStore((state) => state.current?.isAdmin)

	const canEditHologram = hasEditPermission(user, args.hologram, flags)

	const hasPermissionToAddToPlaylist = canAddToPlaylist(user, args.hologram)

	return (
		<DropdownMenu.Content
			align="end"
			asChild={false}
			id="dropdown-menu"
			className={classNames(
				"DropdownMenuContent scroll glass pointer-events-auto relative max-h-96 overflow-y-scroll rounded-lg p-2 text-dark-purple-100 scrollbar-thin scrollbar-track-black/0 scrollbar-thumb-rounded-full dark:text-white dark:scrollbar-thumb-white/40",
			)}>
			<>
				<DropdownMenu.DropdownMenuGroup>
					{canEditHologram && (
						<DropdownMenu.Item
							className="rounded-lg bg-opacity-0 px-3 py-2 hover:bg-black/10 dark:hover:bg-white/10"
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation(), navigate()
							}}>
							<DropdownMenu.Label className="flex flex-row items-center gap-2 text-sm">
								<PencilIcon className="w-4" />
								<div className="flex w-full flex-row justify-between">
									Edit
									{flags?.includes("HOLOGRAMS_UPDATE_ALL") && !isOwner(user!, args.hologram) && (
										<Badge>
											<div className="flex flex-row items-center justify-center gap-2">
												<FlagIcon className="block w-3 stroke-dark-purple-100" />
												<p className="block">Update</p>
											</div>
										</Badge>
									)}
								</div>
							</DropdownMenu.Label>
						</DropdownMenu.Item>
					)}
					{hasPermissionToAddToPlaylist && (
						<DropdownMenu.Sub>
							<DropdownMenu.SubTrigger
								className="rounded-lg bg-opacity-0 px-3 py-2 hover:bg-black/10 dark:hover:bg-white/10"
								onClick={(e) => {
									e.preventDefault(), e.stopPropagation()
								}}>
								<DropdownMenu.Label className="flex flex-row items-center gap-2 text-sm">
									<PlayCircleIcon className="w-4 stroke-dark-purple-100 stroke-2 dark:stroke-white" />
									Add to playlist
								</DropdownMenu.Label>
							</DropdownMenu.SubTrigger>

							<DropdownMenu.Portal>
								<DropdownMenu.SubContent
									className={classNames(
										"DropdownMenuContent",
										"glass max-w-[200px]",
										"-mr-2 -mt-2",
										"data-[side=top]:animate-slideDownAndFade",
										"data-[side=right]:animate-slideLeftAndFade",
										"data-[side=bottom]:animate-slideUpAndFade",
										"data-[side=left]:animate-slideRightAndFade",
										"min-w-[220px] rounded-xl py-2",
										"shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]",
										"will-change-[opacity,transform] dark:text-white",
									)}>
									<AddToPlaylistSubMenu hologram={args.hologram} onClose={args.onClose} mode={"SINGLE"} />
								</DropdownMenu.SubContent>
							</DropdownMenu.Portal>
						</DropdownMenu.Sub>
					)}
				</DropdownMenu.DropdownMenuGroup>
				{/* SHARE CONTENT */}
				<DropdownMenu.Separator
					className={classNames(
						"my-2 h-[1px] bg-white opacity-5",
						Boolean(!user && !canEditHologram) ? "hidden" : null,
					)}
				/>
				<DropdownMenu.DropdownMenuGroup>
					<DropdownMenu.Label className="px-3 pb-2 font-rubik text-sm opacity-30">Share</DropdownMenu.Label>
					<DropdownMenu.Item
						className="rounded-lg bg-opacity-0 px-3 py-2 hover:bg-black/10 dark:hover:bg-white/10"
						onClick={(e) => {
							e.stopPropagation()
							setCopy(getPermalinkUrl(args.hologram.user, args.hologram))
							toast("Copied to clipboard", toastSuccessStyle)
						}}>
						<DropdownMenu.Label className="flex flex-row items-center gap-2 text-sm">
							<LinkIcon className="w-4 fill-black dark:fill-white" />
							Copy Link
						</DropdownMenu.Label>
					</DropdownMenu.Item>
					<DropdownMenu.Item
						className="rounded-lg bg-opacity-0 px-3 py-2 hover:bg-black/10 dark:hover:bg-white/10"
						onClick={(e) => {
							e.stopPropagation()
							args.setSharePopup(args.hologram)
						}}>
						<DropdownMenu.Label className="flex flex-row items-center gap-2 text-sm">
							<ShareIcon className="w-4" />
							Share
						</DropdownMenu.Label>
					</DropdownMenu.Item>
					<DropdownMenu.Item
						className="rounded-lg bg-opacity-0 px-3 py-2 hover:bg-black/10 dark:hover:bg-white/10"
						onClick={(e) => {
							e.stopPropagation()
							setCopy(getEmbedCode(args.hologram))
							toast("Copied to clipboard", toastSuccessStyle)
						}}>
						<DropdownMenu.Label className="flex flex-row gap-2 text-sm">
							<CodeBracketIcon className="w-4 stroke-black dark:stroke-white" />
							Copy Embed Code
						</DropdownMenu.Label>
					</DropdownMenu.Item>
				</DropdownMenu.DropdownMenuGroup>
				{/* DOWNLOAD CONTENT */}

				{/* ONLY RENDER DOWNLOAD CONTENT IF THE USER HAS PERMISSION OR THE CONTENT IS DOWNLOADABLE */}
				{(args.canUsersDownload || flags?.includes("HOLOGRAMS_DOWNLOAD_ALL") || canEditHologram) && (
					<DropdownMenu.DropdownMenuGroup>
						<DropdownMenu.Separator className={classNames("my-2 h-[1px] bg-white opacity-5")} />
						<DropdownMenu.Label className="flex w-full flex-row justify-between gap-2 pb-2 pl-3 text-sm text-white/30">
							<p className="font-rubik">Download</p>
							{flags?.includes("HOLOGRAMS_DOWNLOAD_ALL") && !isOwner(user!, args.hologram) && (
								<Badge>
									<div className="flex flex-row items-center justify-center gap-2">
										<FlagIcon className="block w-3 stroke-dark-purple-100" />
										<p className="block font-semibold">Download</p>
									</div>
								</Badge>
							)}
						</DropdownMenu.Label>
						<DropdownMenu.Item
							className="rounded-lg bg-opacity-0 px-3 py-2 hover:bg-black/10 dark:hover:bg-white/10"
							onClick={(e) => {
								e.stopPropagation()
								args.setDownloadPopup(args.hologram)
							}}>
							<DropdownMenu.Label className="flex flex-row items-center gap-2 text-sm">
								<ArrowDownOnSquareStackIcon className="w-4" />
								Download Assets
							</DropdownMenu.Label>
						</DropdownMenu.Item>
						{/* ONLY SHOW THIS IF THE USER HAS EDIT PERMISSION */}
						{canEditHologram && (
							<DropdownMenu.CheckboxItem
								className="rounded-lg bg-opacity-0 px-3 py-2 hover:bg-black/10 dark:hover:bg-white/10"
								checked={args.canUsersDownload}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									args.toggleCanUsersDownload()
								}}>
								<DropdownMenu.Label className="CheckboxItems-center flex flex-row gap-2 text-sm">
									<div className="w-4">
										<DropdownMenu.ItemIndicator>
											<CheckIcon className="w-4" />
										</DropdownMenu.ItemIndicator>
									</div>
									Enable Downloads
								</DropdownMenu.Label>
							</DropdownMenu.CheckboxItem>
						)}
					</DropdownMenu.DropdownMenuGroup>
				)}
				{/* PRIVACY CONTROLS ONLY RENDER IF USER HAS EDIT PERMISSION*/}
				{canEditHologram && (
					<>
						<DropdownMenu.Separator className="my-2 h-[1px] bg-white opacity-5" />
						<DropdownMenu.Label className="px-3 pb-2 font-rubik text-sm opacity-30">
							Privacy
						</DropdownMenu.Label>
						<DropdownMenu.RadioGroup
							className="flex flex-col gap-2.5"
							value={args.privacy}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
							}}
							onValueChange={(newPrivacy) => {
								args.handlePrivacyUpdate(newPrivacy as PlaylistPrivacy)
							}}
							aria-label="View density">
							{/* SET PUBLIC */}
							<PrivacyItem
								privacy={args.privacy}
								privacyLabel="Public"
								privacyValue={"PUBLIC"}
								description={"Anyone on the internet can see."}
							/>
							{/* SET UNLISTED */}
							<PrivacyItem
								privacy={args.privacy}
								privacyLabel="Unlisted"
								privacyValue={"UNLISTED"}
								description={"Only People with the Link can access."}
							/>
							{/* SET PRIVATE */}
							<PrivacyItem
								privacy={args.privacy}
								privacyLabel="Private"
								privacyValue={"ONLY_ME"}
								description={"Only you can access."}
							/>
						</DropdownMenu.RadioGroup>
					</>
				)}
				{canEditHologram && (
					<>
						<DropdownMenu.Separator className="my-2 h-[1px] bg-white opacity-5" />
						<DropdownMenu.Label className="px-3 pb-2 font-rubik text-sm opacity-30">
							Manage
						</DropdownMenu.Label>
					</>
				)}
				{user && args.hologram?.user.id !== user?.id && isAdmin && (
					<DropdownMenu.Item
						className="rounded-lg bg-red-500 bg-opacity-0 px-3 py-2 hover:bg-opacity-90 hover:text-white"
						onClick={(e) => {
							e.stopPropagation()

							args.setFlagPopup(args.hologram)
						}}>
						<DropdownMenu.Label className="flex flex-row items-center gap-2 text-sm">
							<FlagIcon className="w-4" />
							Report hologram
						</DropdownMenu.Label>
					</DropdownMenu.Item>
				)}

				{/* <DropdownMenu.Separator className="my-2 h-[1px] bg-white opacity-5" /> */}
				{canEditHologram && (
					<DropdownMenu.Item
						className="rounded-lg bg-red-500 bg-opacity-0 px-3 py-2 hover:bg-opacity-90 hover:text-white"
						onClick={(e) => {
							e.stopPropagation()
							args.setDeletePopup([args.hologram.id])
						}}>
						<DropdownMenu.Label className="flex flex-row items-center gap-2 text-sm">
							<TrashIcon className="w-4" />
							Delete hologram
						</DropdownMenu.Label>
					</DropdownMenu.Item>
				)}
			</>
		</DropdownMenu.Content>
	)
}

interface PrivacyItemArgs {
	//current value
	privacy: string
	// label for the UI
	privacyLabel: string
	// value that the component should reflect
	privacyValue: string
	// description of the setting
	description: string
}

function PrivacyItem({ ...args }: PrivacyItemArgs) {
	const { privacy, privacyLabel, privacyValue, description } = args

	return (
		<DropdownMenu.RadioItem
			value={privacyValue}
			disabled={privacyValue === privacy}
			className={classNames(
				"flex cursor-pointer flex-row items-center gap-2.5 rounded-lg p-2",
				`${
					privacyValue === privacy
						? "border-2 border-solid border-purple-500 bg-[rgba(160,85,250,0.20)]"
						: "hover:bg-black/10 dark:hover:bg-white/10"
				}`,
			)}>
			<div
				className={classNames(
					"flex h-[18px] w-[18px] flex-row items-center justify-center rounded-full",
					`${
						privacyValue === privacy
							? "bg-purple-500"
							: "border-2 border-solid border-gray-300 bg-white dark:border-[#60547E] dark:bg-[#28273F]"
					}`,
				)}>
				<DropdownMenu.ItemIndicator>
					<div className="flex h-4 w-4 flex-row items-center justify-center rounded-full bg-white">
						<div className="h-2 w-2 rounded-full bg-purple-500" />
					</div>
				</DropdownMenu.ItemIndicator>
			</div>
			{/* Public */}
			<Tooltip content={description} delayDuration={0}>
				<div className="items center flex flex-row gap-2">
					{privacyLabel === "Public" && <GlobeAmericasIcon className="w-4 dark:fill-[white]" />}
					{/* PRIVATE */}
					{privacyLabel === "Private" && <LockClosedIcon className="w-4 dark:fill-[white]" />}
					{/* UNLISTED */}
					{privacyLabel === "Unlisted" && <EyeSlashIcon className="w-4 dark:fill-[white]" />}

					<label className="text-left text-sm text-black dark:text-white" htmlFor="r1">
						{privacyLabel}
					</label>
				</div>
			</Tooltip>
		</DropdownMenu.RadioItem>
	)
}
