import { CheckIcon, MinusIcon } from "@heroicons/react/24/outline"
import { Variants, motion } from "framer-motion"

interface CheckBoxArgs {
	selected?: boolean
	isASelectAllCheckBox?: boolean
	onClick: (e) => void
	className?: string
	tooltip?: string
}

const variants: Variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
}

export default function CheckBox({ selected, onClick, isASelectAllCheckBox }: CheckBoxArgs) {
	return (
		<div onClick={onClick} className="h-5 w-5 hover:cursor-pointer md:h-6 md:w-6 ">
			{/* Fill circle */}
			{/* Box */}
			<div className="relative h-full w-full rounded-md border-2 border-solid border-[#60547E] transition-colors hover:border-white/50 ">
				{/* Check */}
				<motion.div
					transition={{ easing: "easeInOut", duration: 0.025 }}
					variants={variants}
					initial="closed"
					animate={selected ? "open" : "closed"}
					className="bg-purple-gradient absolute flex h-full w-full items-center justify-center rounded-[3px]">
					{!isASelectAllCheckBox && <CheckIcon className="w-6 stroke-white stroke-2" />}
					{isASelectAllCheckBox && <MinusIcon className="w-6 stroke-white stroke-2" />}
				</motion.div>
			</div>
		</div>
	)
}
