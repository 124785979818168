import { ImageAsset, Privacy } from "@prisma/client"
import { useHover } from "@uidotdev/usehooks"
import BanHologramButton from "components/BanHologramButton"
import DownloadPopup from "components/DownloadPopup"
import HologramCastButton from "components/HologramCastButton"
import HologramThumbnail from "components/HologramThumbnail"
import { motion, useInView } from "framer-motion"
import { useCastStore } from "hooks/useCastStore"
import { classNames } from "lib/classNames"
import { EmbedHologram } from "lib/hologramProps"
import { ClosestImageAssetType, getClosestImage } from "lib/utils.hologram"
import Link from "next/link"
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid"
import { useEffect, useRef, useState } from "react"
import { GetHologramItemResult } from "server/routers/hologram.trpc"
import { twMerge } from "tailwind-merge"
import { MathUtils } from "three"
import styles from "./PreviewCard.module.css"
import PreviewCardFooter from "./PreviewCardFooter"
import CheckBox from "./SelectButton"
import ShareHologramPopup from "components/ShareHologramPopup"
import DeleteHologramPopup from "components/DeleteHologramPopup"
import HologramSettingsPopup from "components/HologramSettingsPopup"
import HologramDropDown from "components/HologramDropDown"
import FlagHologramPopup, { FlagHologram } from "components/moderation/FlagHologramPopup"
import AddHologramToGoButton from "components/AddHologramToGoButton"
import React from "react"

export interface PreviewCardArgs {
	hologram: EmbedHologram
	animateOnHover?: boolean
	autoplay?: boolean

	// Select Props
	selectable?: boolean
	selected?: boolean
	onSelected?: any
	playlistItemID?: number

	// show any elements
	basicPreview?: boolean

	forceAspectRatio?: number
	className?: string
}

const getVideoAssets = (hologram: EmbedHologram) => {
	return hologram.imageAssets?.filter(({ type }) => type === "video/mp4")
}

const MotionLink = motion(Link)

const PreviewCard = (args: PreviewCardArgs) => {
	const {
		hologram,
		animateOnHover = false,
		autoplay = false,
		selectable,
		onSelected,
		basicPreview = false,
		playlistItemID = undefined,
		forceAspectRatio,
		className,
	} = args
	const { user, id } = hologram
	let { aspectRatio } = hologram
	const { username } = user

	const [hasPlayedOnce, setHasPlayedOnce] = useState(autoplay)
	const [isImageLoading, setIsImageLoading] = useState(true)
	const [ref, hovering] = useHover<HTMLAnchorElement>()
	const [isTouching, setIsTouching] = useState(false)
	const videoRef = useRef<HTMLVideoElement>(null)
	const inView = useInView(videoRef, { once: true })
	const [downloadPopup, setDownloadPopup] = useState<EmbedHologram | null>(null)
	const [sharePopup, setSharePopup] = useState<EmbedHologram | null>(null)
	const [deletePopup, setDeletePopup] = useState<number[] | null>(null)
	const [flagPopup, setFlagPopup] = useState<FlagHologram | null>(null)
	const [openSettings, setOpenSettings] = useState<boolean>(false)

	const castHologram = useCastStore((store) => store.hologram)
	const castPlaylistItemId = useCastStore((store) => store.playlistItemId)

	const hologramUrl = hologram.privacy === "PUBLIC" ? `/${username}/${id}` : `/${username}/${hologram.uuid}`

	const videoAssets = getVideoAssets(hologram)
	const video = videoAssets && getClosestImage(videoAssets, 300)
	const videoUrl = video?.url ?? ""
	const [isPlaying, setIsPlaying] = useState(false)
	const [privacy, setPrivacy] = useState<Privacy>(hologram.privacy)

	const showVideo = videoUrl && (autoplay || animateOnHover)

	if (aspectRatio === 0 && video && !forceAspectRatio) {
		aspectRatio = video.width! / video.height!
	}

	aspectRatio = forceAspectRatio ?? aspectRatio

	useEffect(() => {
		if (inView && videoRef.current) {
			videoRef.current.preload = "metadata"
			return
		} else if (!inView) {
			Pause()
		}
	}, [inView, videoRef.current])

	const Play = () => {
		if (!videoRef.current || !animateOnHover || autoplay || !videoUrl) return
		const { current } = videoRef
		if (current.readyState <= 1) {
			videoRef.current.preload = "auto"
			videoRef.current.load()
			return
		}
		videoRef.current.play()
	}

	const Pause = () => {
		if (isPlaying && videoRef.current) {
			videoRef.current.pause()
		}
	}

	const onLoadedVideoMetadata = () => {
		const { current } = videoRef
		if (current?.duration && !Number.isNaN(current?.duration)) {
			current.currentTime = (current.duration * 2) / 3 - 1
		}
	}

	const onPlay = () => {
		const { current } = videoRef
		if (!current) return
		if (!hovering && !isTouching && !autoplay) {
			current.pause()
		}
		if (!hasPlayedOnce) setHasPlayedOnce(true)
		setIsPlaying(true)
	}
	const onPause = () => {
		setIsPlaying(false)
	}

	const onImageLoaded = () => {
		const delay = MathUtils.randInt(0, 100)
		setTimeout(() => setIsImageLoading(false), delay)
	}

	function onShareClose() {
		setSharePopup(null)
	}

	function onDownloadClose() {
		setDownloadPopup(null)
	}

	return (
		<MotionLink
			href={encodeURI(hologramUrl)}
			onTouchStart={Play}
			onTouchEnd={Pause}
			onMouseOver={Play}
			onMouseLeave={Pause}
			className={classNames(
				"hologram-preview-card group/card relative z-0",
				className,
				`${args.selected ? `${styles.gradientBorder}` : ""}`,
				// if we're casting a hologram, display the cast style
				`${hologram.id === castHologram?.id && castPlaylistItemId === undefined ? styles.pulse : ""}`,
				// if we're casting a playlistItem, display the cast style
				`${playlistItemID === castPlaylistItemId && playlistItemID !== undefined ? styles.pulse : ""}`,
			)}
			id={`hologram-${id}`}
			style={{
				aspectRatio: `${aspectRatio || 0.75}`,
			}}
			whileHover={{ scale: isImageLoading ? 1 : 1.03 }}
			ref={ref}>
			{
				// Transparent layer above card
				<div className={twMerge("absolute z-20 h-full w-full")}>
					{!basicPreview && (
						<>
							<div
								className={twMerge(
									"absolute right-3 top-3 flex flex-row gap-1 transition-opacity",
									isImageLoading ? "scale-0" : null,
								)}>
								<AddHologramToGoButton
									hologram={hologram}
									className="border border-solid border-white/0 transition-all hover:border-white/40 hover:bg-white/20 md:opacity-0 md:group-hover/card:opacity-100"
								/>

								<HologramCastButton
									hologram={hologram}
									playlistItemId={playlistItemID}
									fillColor={hologram.id === castHologram?.id ? "fill-green-400" : "fill-white"}
									className="hidden h-11 w-11 rounded-full border border-solid border-white/0 bg-white/0 p-3 transition-all backdrop:blur-none hover:border-white/40 hover:bg-white/20 hover:shadow-xl hover:backdrop-blur-lg md:block md:opacity-0 md:group-hover/card:opacity-100"
								/>

								<BanHologramButton
									id={id}
									className="bg-black bg-opacity-0 hover:bg-white hover:bg-opacity-20 hover:backdrop-blur-lg md:opacity-0 md:group-hover/card:opacity-100"
								/>
								<div className="hidden md:block">
									<HologramDropDown
										className="border border-solid border-white/0 bg-white/0 transition-all backdrop:blur-none hover:border-white/40 hover:bg-white/20 hover:backdrop-blur-lg md:opacity-0 md:group-hover/card:opacity-100"
										//@ts-ignore - TEMPORARY
										hologram={hologram}
										privacy={privacy}
										setPrivacy={setPrivacy}
										setDownloadPopup={setDownloadPopup}
										setFlagPopup={setFlagPopup}
										setSharePopup={setSharePopup}
										setDeletePopup={setDeletePopup}
										forceDarkMode={true}
									/>
								</div>
								<div className="flex h-11 w-11 items-center justify-center rounded-full border border-solid border-white/0 bg-white/0 transition-all hover:border-white/40 hover:bg-white/20 hover:shadow-xl hover:backdrop-blur-lg md:hidden md:opacity-0 md:group-hover/card:opacity-100">
									<button
										className="flex flex-row items-center justify-center gap-2 rounded-lg"
										onClick={(e) => {
											e.stopPropagation(), e.preventDefault(), setOpenSettings(!openSettings)
										}}>
										<EllipsisHorizontalIcon className="w-5 stroke-2 drop-shadow-lg" />
									</button>
								</div>
							</div>
						</>
					)}
					{selectable && (
						<div
							className={twMerge(
								"absolute left-0.5 top-0.5 opacity-100 transition-opacity md:left-3 md:top-3",
								args.selected ? "md:opacity-100" : "md:opacity-0",
								isImageLoading ? "scale-75" : "group-hover/card:opacity-100",
							)}>
							<CheckBox
								selected={args.selected}
								onClick={
									onSelected
										? (e) => {
												e.preventDefault()
												e.stopPropagation(), onSelected()
											}
										: () => {}
								}
							/>
						</div>
					)}
					{user && (
						<PreviewCardFooter
							classNames={isImageLoading ? "" : "group-hover/card:opacity-100"}
							title={hologram.title ?? ""}
							user={user}
						/>
					)}
				</div>
			}
			{showVideo && (
				<video
					ref={videoRef}
					className={twMerge(
						"absolute z-10 h-full w-full scale-[1.01] opacity-0 transition-opacity group-hover:opacity-100 group-active:opacity-100",
						hasPlayedOnce || autoplay ? "opacity-100" : "",
						isImageLoading && !isPlaying ? "opacity-0" : "",
						forceAspectRatio &&
							"absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover",
					)}
					onLoadedMetadata={onLoadedVideoMetadata}
					onPlay={onPlay}
					onPause={onPause}
					playsInline
					loop
					muted
					preload={autoplay ? "auto" : "none"}
					autoPlay={autoplay}
					src={videoUrl}
				/>
			)}
			<HologramThumbnail onImageLoaded={onImageLoaded} hologram={hologram} width={600} />

			{/* POPUPS */}
			{/* we don't want to mount the popups unless they're valid */}
			{sharePopup !== null && (
				<ShareHologramPopup
					hologram={sharePopup}
					popup={{ open: sharePopup !== null, onClose: onShareClose }}
				/>
			)}

			{downloadPopup !== null && (
				<DownloadPopup
					hologram={downloadPopup}
					popup={{ open: downloadPopup !== null, onClose: onDownloadClose }}
				/>
			)}

			{deletePopup !== null && (
				<DeleteHologramPopup ids={[hologram.id]} open={!!deletePopup} setOpen={setDeletePopup} />
			)}

			{flagPopup !== null && (
				<FlagHologramPopup hologram={flagPopup} open={!!flagPopup} setOpen={setFlagPopup} />
			)}
			{openSettings !== false && (
				<HologramSettingsPopup
					open={openSettings}
					setOpen={setOpenSettings}
					//@ts-ignore - TEMPORARY
					hologram={hologram}
					privacy={privacy}
					setPrivacy={setPrivacy}
					setFlagPopup={setFlagPopup}
					setDownloadPopup={setDownloadPopup}
					setSharePopup={setSharePopup}
					setDeletePopup={setDeletePopup}
				/>
			)}
		</MotionLink>
	)
}

export default PreviewCard
