import { trpc } from "lib/trpc/trpc"
import { holographicPlaylistTitles } from "lib/utils.playlist"

export function usePlaylists() {
	/**create a new Playlist */
	const { mutateAsync: createPlaylistMutation } = trpc.playlist.createPlaylist.useMutation()

	const { refetch } = trpc.playlist.myPlaylists.useQuery()

	/**create a new playlist, assign it a fun title, append the playlist to the original fetch */
	async function createPlaylist() {
		await createPlaylistMutation({
			// grab a random fun title
			title: holographicPlaylistTitles[Math.floor(Math.random() * 49) + 1],
		})

		await refetch()
	}

	return {
		createPlaylist,
	}
}
