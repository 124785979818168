import { GlobeAmericasIcon, EyeSlashIcon, LockClosedIcon } from "@heroicons/react/24/outline"
import { classNames } from "lib/classNames"
import { Tooltip } from "./Tooltip"

interface PrivacyIconArgs {
	privacy: "PUBLIC" | "UNLISTED" | "ONLY_ME" | "PASSWORD"
	// icon is an outline, so if you pass in color it needs to be stroke-*
	className?: string
}

export function PrivacyIcon(args: PrivacyIconArgs) {
	let className = args.className?.includes("stroke-")
		? args.className
		: "dark:stroke-white stroke-dark-purple-100 w-6"

	switch (args.privacy) {
		case "PUBLIC":
			return <GlobeAmericasIcon className={classNames(args.className, className)} />
		case "UNLISTED":
			return <EyeSlashIcon className={classNames(args.className, className)} />
		case "ONLY_ME":
			return <LockClosedIcon className={classNames(args.className, className)} />
		// currently unsupported
		case "PASSWORD":
			return <></>
	}
}

export function PrivacyIconWithToolTip(args: PrivacyIconArgs) {
	let prettyName = ""

	switch (args.privacy) {
		case "PUBLIC":
			prettyName = "Public"
			break
		case "UNLISTED":
			prettyName = "Unlisted"
			break
		case "ONLY_ME":
			prettyName = "Private"
			break
		case "PASSWORD":
			prettyName = "Password"
	}

	return (
		<Tooltip content={prettyName}>
			{/* extra div necessary for hover effect on tooltip */}
			<div className="w-6">
				<PrivacyIcon privacy={args.privacy} className={args.className} />
			</div>
		</Tooltip>
	)
}
