import HologramThumbnail, { HologramThumbnailData } from "./HologramThumbnail"
import { GetPlaylistThumbnailResult } from "server/routers/playlist/playlist.trpc"
import { twMerge } from "tailwind-merge"
import { EmbedHologram } from "lib/hologramProps"

interface PlaylistThumbnailProps {
	random?: boolean
	size?: number
	className?: string
	playlist: GetPlaylistThumbnailResult
}

const getSelectedHolograms = (holograms?: HologramThumbnailData[], random?: boolean) => {
	if (!holograms) return []

	if (holograms.length > 4 && random) {
		let remainingHolograms = holograms
		const randomHolograms = new Array(4).fill(0).map(() => {
			const length = remainingHolograms.length
			const index = Math.floor(Math.random() * (length - 1))
			const randomItem = remainingHolograms[index]
			remainingHolograms.splice(index, 1)
			return randomItem
		})
		return randomHolograms
	} else return holograms.slice(0, 4)
}

export default function PlaylistThumbnail(props: PlaylistThumbnailProps) {
	const { playlist, random, className, size = 120 } = props

	const holograms = playlist?.map((item) => item.hologram)
	const selectedHolograms = getSelectedHolograms(holograms, random) as unknown as EmbedHologram[]

	const tileWidth = size / 2
	const tileHeight = size / 2
	const topLeftClassName = "absolute top-0 left-0 rounded-none w-1/2"
	const topRightClassName = `absolute top-0 right-0 rounded-none w-1/2`
	const bottomLeftClassName = "absolute bottom-0 left-0 rounded-none w-1/2"
	const bottomRightClassName = "absolute bottom-0 right-0 rounded-none w-1/2"

	return (
		<div
			style={{ height: size, width: size }}
			className={twMerge(
				`relative overflow-hidden rounded-md`,
				className,
				`${selectedHolograms.length < 4 ? "bg-black/10 dark:bg-white/10" : ""}`,
			)}>
			{selectedHolograms.map((hologram, index) => (
				<HologramThumbnail
					key={index}
					containerClassName={twMerge(
						index === 0 && topLeftClassName,
						index === 1 && topRightClassName,
						index === 2 && bottomLeftClassName,
						index === 3 && bottomRightClassName,
					)}
					hologram={hologram}
					width={tileWidth}
					height={tileHeight}
				/>
			))}
		</div>
	)
}
