import CheckIcon from "components/icons/CheckIcon"
import { Variants, motion } from "framer-motion"

interface CheckBoxArgs {
	selected?: boolean
	onClick: (e) => void
	className?: string
}

const variants: Variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
}

export default function CheckBox({ selected, onClick }: CheckBoxArgs) {
	return (
		<div
			onClick={onClick}
			className="group/box pointer-events-auto relative flex h-[48px] w-[48px] items-center justify-center hover:cursor-pointer">
			{/* Fill circle */}
			<div className="flex h-full w-full flex-row items-center justify-center rounded-full border border-solid border-white/0 bg-white/0 transition-opacity hover:border-white/40 hover:bg-white/20 hover:shadow-lg hover:backdrop-blur-2xl">
				{/* Box */}
				<div className="relative h-[20px] w-[20px] rounded-md border-2 border-solid border-white drop-shadow-md">
					{/* Check */}
					<motion.div
						transition={{ easing: "easeInOut", duration: 0.1 }}
						variants={variants}
						animate={selected ? "open" : "closed"}
						className="absolute flex h-full w-full items-center justify-center bg-white">
						<CheckIcon fill="transparent" />
					</motion.div>
				</div>
			</div>
		</div>
	)
}
