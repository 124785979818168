import { trpc } from "lib/trpc/trpc"

type HologramPrivacy = "PUBLIC" | "UNLISTED" | "ONLY_ME"

type orderBy = "Added" | "Uploaded" | "Modified" | "Duration" | "Popularity"

export default function useUpdateHolograms() {
	const { mutateAsync: updateHologramPrivacyMutation } = trpc.hologram.updateHologramPrivacy.useMutation()

	async function updateHologramPrivacy(id: number, newPrivacy: HologramPrivacy) {
		const { privacy } = await updateHologramPrivacyMutation({ id, privacy: newPrivacy })

		return privacy
	}
	const { mutateAsync: updateHologramDownloadableMutation } =
		trpc.hologram.updateHologramDownloadable.useMutation()

	async function updateHologramDownloadable(id: number, downloadable: boolean) {
		const { canUsersDownload } = await updateHologramDownloadableMutation({
			id,
			isDownloadable: downloadable,
		})

		return canUsersDownload
	}

	return { updateHologramPrivacy, updateHologramDownloadable }
}
