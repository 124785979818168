import type { PreviewCardFragment } from "graphql/client/gql/types"
import Image from "next/image"
import { twMerge } from "tailwind-merge"

interface PreviewCardFooterArgs {
	title: string
	user: Omit<PreviewCardFragment["user"], "username" | "displayName"> & {
		username: string | null
		displayName: string | null
	}
	classNames?: string
}

export default function PreviewCardFooter(args: PreviewCardFooterArgs) {
	const { title, user, classNames } = args
	const { picture } = user

	return (
		<div
			className={twMerge(
				"absolute bottom-[-1px] z-20 flex w-full items-center justify-between gap-2 rounded-bl-lg rounded-br-lg bg-gradient-to-t from-[rgba(0,0,0,0.6)] p-2 opacity-0 transition-opacity duration-300  group-active/card:opacity-100 md:opacity-0",
				classNames,
			)}>
			<div className="w-full truncate text-sm font-bold text-white">{title}</div>
			<Image
				alt={"avatar"}
				className="h-6 w-6 rounded-full"
				width={25}
				height={25}
				src={picture ?? "/foil-avatar.jpg"}
			/>
		</div>
	)
}
